import {Link} from 'gatsby';

function Logo() {
  return (
    <div className="mx-auto flex">
      <div className="flex-1"></div>
      <Link
        className="flex justify-center hover:no-underline bg-white py-4 px-8 rounded-lg"
        rel="nofollow"
        to="/"
      >
        <img
          src="/favicon.png"
          alt="Logo"
          width="28"
          height="28"
          className="mb-0 mr-2"
        />
        <div className="logo-text font-bold text-gray-700 text-xl">
          Laquiz.tilyupo.com
        </div>
      </Link>
      <div className="flex-1"></div>
    </div>
  );
}

export default Logo;
