import {ReactNode, useRef} from 'react';
import {AnswerId, useQuestion} from './question';

import {useAudio} from '../audio-context';

interface Props {
  children?: ReactNode;
  c?: boolean;
}

function Answer({children, c: correct}: Props) {
  const question = useQuestion();
  const idRef = useRef(0 as AnswerId);
  const isFirstRender = useRef(true);
  if (isFirstRender.current) {
    isFirstRender.current = false;
    idRef.current = question.registerAnswer();
  }

  const id = idRef.current;

  const {success, fail} = useAudio();

  return (
    <div
      className={`
          answer
          ${id % 2 === 1 ? 'even' : 'odd'}
          ${question.selectedAnswer !== undefined && correct ? 'correct' : ''}
          ${id === question.selectedAnswer && !correct ? 'wrong' : ''}
          ${
            question.selectedAnswer === undefined
              ? 'hover:bg-orange-400 cursor-pointer'
              : ''
          }
        `}
      onClick={() => {
        if (id !== undefined && question.selectedAnswer === undefined) {
          question.selectAnswer(id);
          if (correct) {
            success();
            question.onRight();
          } else {
            fail();
            question.onWrong();
          }
        }
      }}
    >
      {children}
    </div>
  );
}

export default Answer;
