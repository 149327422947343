/**
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import {graphql, useStaticQuery} from 'gatsby';
import {ReactNode} from 'react';

interface Props {
  title: string;
  questions?: Array<{
    subject: string;
    question: string;
    answers: Array<{text: string; correct: boolean}>;
  }>;
  description: string;
  type?: 'quiz' | 'website';
  children?: ReactNode;
}

const Seo = ({description, title, questions, children, type}: Props) => {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      {type && <meta property="og:type" content={type} />}
      <meta name="twitter:card" content="summary" />
      <meta property="og:site_name" content="laquiz.tilyupo.com" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      {/* todo: add article image - https://developers.google.com/search/docs/appearance/structured-data/article */}
      {questions && questions.length > 0 && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
            {
              "@context": "https://schema.org/",
              "@type": "Quiz",
              "name": "${title}",
              "hasPart": [${questions
                .map(
                  question => `{
                    "@type": "Question",
                    "typicalAgeRange": "7-",
                    "about": {
                      "@type": "Thing",
                      "name": "Fractions"
                    },
                    "educationalLevel": "intermediate",
                    "eduQuestionType": "Multiple choice",
                    "learningResourceType": "Practice problem",
                    "assesses": "Knowledge of ${question.subject}",
                    "encodingFormat": "text/markdown",
                    "text": "${question.question}",
                    "suggestedAnswer": [
                      ${question.answers
                        .map((answer, index) => [answer.text, index] as const)
                        .filter(
                          x =>
                            x[1] !== question.answers.findIndex(x => x.correct)
                        )
                        .map(
                          ([answer, index]) => `{
                            "@type": "Answer",
                            "position": ${index + 1},
                            "encodingFormat": "text/markdown",
                            "text": "${answer}"
                          }`
                        )
                        .join(', ')}
                    ],
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "position": ${question.answers.findIndex(x => x.correct)},
                      "encodingFormat": "text/markdown",
                      "text": "${question.answers.find(x => x.correct)!.text}"
                    }
                  }`
                )
                .join(', ')}]
            }`,
          }}
        />
      )}
      {children}
    </>
  );
};

export default Seo;
