import {createContext, PropsWithChildren, useMemo} from 'react';
import useSound from 'use-sound';

import {useRequiredContext} from '../utils';
import FailMp3 from './sound/fail.mp3';
import SuccessMp3 from './sound/success.mp3';

const AudioContext = createContext<any>(undefined);

export function AudioContextProvider({children}: PropsWithChildren<{}>) {
  const [success] = useSound(SuccessMp3, {
    volume: 0.8,
    interrupt: true,
  });
  const [fail] = useSound(FailMp3, {
    interrupt: true,
    volume: 0.05,
  });

  const controls = useMemo(() => ({success, fail}), [fail, success]);

  return (
    <AudioContext.Provider value={controls}>{children}</AudioContext.Provider>
  );
}

export function useAudio() {
  return useRequiredContext(AudioContext);
}
