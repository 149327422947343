import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Brand, useRequiredContext} from '../../utils';

export type AnswerId = Brand<number, 'AnswerId'>;

interface QuestionController {
  registerAnswer: () => AnswerId;
  onRight: () => void;
  onWrong: () => void;
  selectedAnswer: AnswerId | undefined;
  selectAnswer: (id: AnswerId) => void;
}

const QuestionContext = createContext<QuestionController | undefined>(
  undefined
);

interface Props {
  marginBottom: number;
  children: ReactNode;
  onRight: () => void;
  onWrong: () => void;
}

function Question({marginBottom, children, onRight, onWrong}: Props) {
  const [answer, setAnswer] = useState<AnswerId>();
  const containerRef = useRef<HTMLDivElement>(null);
  const handleAnswer = useCallback(
    (id: AnswerId) => {
      setAnswer(id);
      setTimeout(() => {
        window.scrollTo({
          behavior: 'smooth',
          left: window.scrollX,
          top:
            window.scrollY +
            (containerRef.current?.getBoundingClientRect()?.height ?? 0) +
            marginBottom,
        });
      }, 500);
    },
    [marginBottom]
  );
  const answerCounter = useRef(0);
  const controller = useMemo<QuestionController>(
    () => ({
      onRight,
      onWrong,
      selectedAnswer: answer,
      selectAnswer: handleAnswer,
      registerAnswer: () => answerCounter.current++ as AnswerId,
    }),
    [answer, handleAnswer, onRight, onWrong]
  );

  return (
    <QuestionContext.Provider value={controller}>
      <div className="question" style={{marginBottom}} ref={containerRef}>
        {children}
      </div>
    </QuestionContext.Provider>
  );
}

export default memo(Question);

export function useQuestion() {
  return useRequiredContext(QuestionContext);
}
