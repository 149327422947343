import {
  Context,
  EffectCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

export function useEffectOnce(effect: EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
}

export function useRequiredContext<T>(context: Context<T | undefined>) {
  const value = useContext(context);
  if (value === undefined) {
    throw new Error(`${context.displayName ?? 'context'} don't have provider`);
  }

  return value as T;
}

export type Brand<T, K> = T & {__brand: K};

export function useHeadsObserver() {
  const observer = useRef<IntersectionObserver>();
  const [activeId, setActiveId] = useState('');

  useEffect(() => {
    const handleObsever: IntersectionObserverCallback = entries => {
      entries.forEach(entry => {
        if (entry?.isIntersecting) {
          setActiveId(entry.target.id);
        }
      });
    };

    observer.current = new IntersectionObserver(handleObsever, {
      rootMargin: '-20% 0% -35% 0px',
    });

    const elements = document.querySelectorAll('h2');
    elements.forEach(elem => observer.current?.observe(elem));
    return () => observer.current?.disconnect();
  }, []);

  return {activeId};
}

export function maxPrefixLength(str1: string, str2: string) {
  let i = 0;
  for (; i < Math.min(str1.length, str2.length) && str1[i] === str2[i]; i++);
  return i;
}
