import React, {useCallback, useState} from 'react';
import {AudioContextProvider} from '../components/audio-context';
import Footer from '../components/footer';
import Logo from '../components/logo';
import Answer from '../components/quiz/answer';
import Question from '../components/quiz/question';
import Seo from '../components/seo';

const Quiz = ({pageContext: {quiz}}: any) => {
  const contentRef = React.useRef<HTMLDivElement>(null);

  const [wrong, setWrong] = useState(0);
  const [right, setRight] = useState(0);

  const onRight = useCallback(() => setRight(x => x + 1), []);
  const onWrong = useCallback(() => setWrong(x => x + 1), []);

  return (
    <div className="quiz">
      <header className="center-wrapper page-header">
        <Logo />
      </header>
      <main>
        <article
          className="article"
          itemScope
          itemType="http://schema.org/CreativeWork"
        >
          <header className="quiz-header pb-32">
            <div className="center-wrapper text-center">
              <h1 itemProp="headline">{quiz.title}</h1>
            </div>
          </header>
          <div className="content-wrapper center-wrapper relative bottom-36 bg-white rounded-xl pb-10">
            <div ref={contentRef} className="content">
              <AudioContextProvider>
                <section className="body content-body" itemProp="text">
                  {quiz.doc.image && (
                    <img
                      src={quiz.doc.image.url.replace(
                        'cdn.laquiz.org',
                        'laquiz.fra1.digitaloceanspaces.com'
                      )}
                      alt={`${quiz.doc.title} Quiz Cover`}
                      width={quiz.doc.image.width}
                      height={quiz.doc.image.height}
                      className="mx-auto mb-10"
                    />
                  )}
                  <p>{quiz.description}</p>
                  {quiz.questions.map(q => (
                    <Question
                      key={q.question}
                      onRight={onRight}
                      onWrong={onWrong}
                      marginBottom={32}
                    >
                      <p>{q.question}</p>

                      {q.answers.map(x => (
                        <Answer key={x.text} c={x.correct}>
                          {x.text}
                        </Answer>
                      ))}
                    </Question>
                  ))}
                </section>
                <div className="font-bold text-xl flex gap-16 pt-16 pb-20">
                  <div className="flex-1" />
                  <div className="flex-1 text-center">
                    <div className="text-8xl  text-green-500">{right}</div>
                    <div>right</div>
                  </div>
                  <div className="flex-1 text-center">
                    <div className="text-8xl text-red-500">{wrong}</div>
                    <div>wrong</div>
                  </div>
                  <div className="flex-1" />
                </div>
              </AudioContextProvider>
            </div>
          </div>
        </article>
      </main>
      <Footer />
    </div>
  );
};

export const Head = ({pageContext: {quiz}}: any) => {
  return (
    <Seo
      title={quiz.title}
      description={
        quiz.description ??
        `Discover your knowledge about ${quiz.doc.title} by taking our exciting quiz! With ${quiz.questions.length} ` +
          "thought-provoking questions, it's a fun way to test your expertise or learn something new. " +
          'Are you up for the challenge? Start the quiz now!'
      }
      type="quiz"
      questions={quiz.questions.map(question => ({
        answers: question.answers,
        question: question.question,
        subject: quiz.doc.title,
      }))}
    />
  );
};

export default Quiz;
